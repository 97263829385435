.fragment {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;

    &__center {
        width: 100%;
        max-width: $dim-breakpoint-lg;
        display: flex;
        flex-direction: column;
    }

    &__title {
        padding: 2rem 0;
        font-size: 3rem;
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
        color: $color-primary;
        width: 100%;

        &.inverse {
            color: $color-white;
            background-color: $color-primary;
        }

        &.padding-lg {
            padding: 4rem 0;
        }
    }

    &__content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        padding: 2rem 0;
        width: 100%;
    }

    &__photo {
        flex: 1 0 50%;

        &-img {
            width: 100%;
        }
    }

    &__text {
        padding: 2rem;
        font-size: 1.6rem;
    }
}
