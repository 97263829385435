@import "../scss/variables";

.developer-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    z-index: 1;

    &__developer {
        flex: 0 0 (100%/3);
        // height: 50vh;
        padding: 0 2vh 2vh 0;

        &-content {
            width: 100%;
            border: 2px solid $color-white;
            box-shadow: 0 0.2vh 1vh 0 rgba(black, 0.2);
            border-radius: 1vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            -webkit-tap-highlight-color: transparent;
            transition: border 0.2s ease-in-out;

            &:hover {
                border-color: $color-primary;
            }
        }

        &-logo {
            width: 100%;
            padding-top: 70%;
            position: relative;

            &-img {
                position: absolute;
                width: 90%;
                height: 90%;
                object-fit: contain;
                top: 5%;
                left: 5%;
            }
        }
    }
}
