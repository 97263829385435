.developer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    &__go-back {
        margin-bottom: 2vh;

        &-button {
            display: flex;
            align-items: center;
            text-decoration: none;
            color: $color-primary;
            padding: 0.5vh 2vh 0.5vh 1vh;
            border-radius: 2vh;
            font-size: 1.5vh;
            box-shadow: 0 0.2vh 1vh 0 rgba(black, 0.2);
            transition: all 0.2s ease-out;

            &:hover {
                background-color: $color-primary;
                color: $color-white;
            }

            .icon {
                @include ff-icon;
                font-size: 2.5vh;
                padding: 0;
                margin: 0 0.5vh 0 0;
                width: 2vh;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    &__about {
        display: flex;
        align-items: stretch;
        border-radius: 1vh;
        box-shadow: 0 0.2vh 1vh 0 rgba(black, 0.2);
        background-color: $color-white;
        width: calc(100% - 2vh);
    }

    &__logo {
        flex: 0 0 40%;
        padding: 1vh;
        height: 20vh;
        border-right: 1px solid $color-grey-1;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 2vh;
        flex: 0 0 60%;
    }

    &__name {
        font-size: 3vh;
        font-weight: bold;
        color: $color-secondary;
    }

    &__stand {
        font-size: 2vh;
        padding-top: 1vh;
        color: $color-grey-7;
    }

    &__text {
        padding-top: 1vh;
        font-size: 1.6vh;
        color: $color-grey-7;

        a {
            color: $color-secondary;
        }
    }

    &__apartments {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 2vh 0 0 0;
        width: 100%;
    }

    &__apartment {
        flex: 0 0 (100% / 3);
        padding: 0 2vh 2vh 0;

        &-wrapper {
            background-color: $color-white;
            box-shadow: 0 0.2vh 1vh 0 rgba(black, 0.2);
            border-radius: 1vh;
            padding: 0 1vw;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border: 2px solid $color-white;
            transition: all 0.2s ease-out;
            cursor: pointer;

            &:hover {
                border-color: $color-primary;
            }
        }

        &-layout {
            width: 100%;
            position: relative;
            padding: 0 1vh;

            &-img {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;

                object-fit: contain;
            }
        }

        &-project {
            width: 100%;
            text-align: center;
            padding: 1vw 0;
            font-size: 2vh;
            font-weight: bold;
            @extend .color-secondary;
            // color: $color-secondary;
            // border-bottom: 1px solid $color-grey-1;
        }

        &-name {
            width: 100%;
            text-align: center;
            padding: 2vh 1vh 1vh;
            font-size: 2vh;
            font-weight: bold;
            @extend .color-secondary;
        }

        &-area {
            width: 100%;
            text-align: center;
            padding: 0 1vh 1vh;
            font-size: 1.8vh;
            @extend .color-secondary;
        }
    }

    &__viewer3d {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;

        &-background {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(black, 0.8);
            z-index: 1;
        }

        &-viewer {
            width: 90vw;
            height: 90vh;
            margin: 5vh 5vw;
            z-index: 2;
            border-radius: 0.5vh;
            background-color: black;
            box-shadow: 0 0.2vh 1vh 0 rgba(black, 0.7);
            position: relative;
        }

        &-iframe {
            width: 100%;
            height: 100%;
            border-radius: 0.5vh;
            z-index: 1;
        }

        &-close {
            position: absolute;
            top: 1vh;
            right: 1vh;
            width: 4vh;
            height: 4vh;
            background-color: $color-secondary;
            @include ff-icon;
            font-size: 3vh;
            color: $color-white;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0.5vh;
            cursor: pointer;
            z-index: 2;
        }
    }
}
