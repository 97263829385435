.overlay {
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    position: absolute;
    background-color: rgba(white, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    align-items: center;
    z-index: 10;

    &__title {
        font-size: 2rem;
        text-align: center;
        width: 100%;
        max-width: 500px;
        text-transform: uppercase;
    }

    &__description {
        font-size: 1.6rem;
        text-align: center;
        width: 100%;
        max-width: 500px;
    }

    &__children {
        margin-top: 2rem;
    }
}
