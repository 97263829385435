@import "../scss/variables";

.header {
    display: flex;
    height: 10vh;
    box-shadow: 0 0 1vh 0 rgba(black, 0.2);
    // border-bottom: 1px solid $color-grey-3;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    justify-content: center;
    background-color: $color-white;
    z-index: 10;

    &__center {
        width: 100%;
        height: 100%;
        max-width: $dim-xlg;
        display: flex;
        justify-content: space-between;
        padding: 0 2vh;
    }

    &__logo {
        height: 100%;
        padding: 1vh;
        flex: 0 0 auto;

        &-img {
            height: 100%;
            display: block;
            position: relative;
        }
    }
}
