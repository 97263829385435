@import "../scss/variables";

.router {
    padding-top: 12vh;
    display: flex;
    justify-content: center;
    position: relative;

    &__center {
        width: 100%;
        max-width: $dim-xlg;
        display: flex;
        align-items: flex-start;
        position: relative;
        padding: 0 2vh;
    }

    &__main {
        // flex: 0 0 55%;
        display: flex;
        position: relative;
        z-index: 3;
        // border-right: 1px solid $color-grey-1;
    }

    &__aside {
        // flex: 0 0 45%;
        // margin-top: 2rem;
        // border: 1px solid $color-grey-3;
        position: sticky;
        top: 12vh;
        padding: 2vh;
        // box-shadow: 0 0.2vh 1vh 0 rgba(black, 0.2);
        border-radius: 1vh;
        z-index: 2;

        img {
            width: 100%;
        }
    }
}
